body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
table{
	border: 1px solid rgba(0,0,0, 0.99);
	margin:20px;
}
thead{
	font-weight:600;
}
table td{
	border: 1px solid rgba(0,0,0, 0.99);
	padding:3px;
}
